import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Navbar } from "react-bootstrap";
import { faEnvelope, faLocationDot, faPhoneVolume, } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import EnquiryModal from "../EnquiryModal/EnquiryModal";


function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [modalShow, setModalShow] = useState(false);


  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      <section className="main-header">
        <div className="black-header-top">
          <Navbar collapseOnSelect expand="lg">
            <Link to={"/"}>
              <Navbar.Brand>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/logo/NCDOE Logo - Transparent Background (1).png"
                  }
                  className="logo-img"
                  alt="..."
                  onClick={() => handleNavItemClick("/")}
                />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <div className="main-text-header">
                <p className="header-txttt me-4" >
                  MBA online
                </p>
                <p className="header-txttt me-4 " >
                  Master of Business Administration (Working Executives)
                </p>
                <p className="header-txttt" >
                  <button class="enquiry-btnnn" onClick={() => setModalShow(true)}>Enquire Now  </button>
                </p>
              </div>
              <div className="mobile-view-header mt-3">
                <p>
                  MBA online
                </p>
                <p >
                  Master of Business Administration (Working Executives)
                </p>
                <button class="enquiry-btnnn" onClick={() => setModalShow(true)}>Enquire Now  </button>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <EnquiryModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </section>
    </>
  );
}

export default Header;
