import React from 'react'
import './WhatsappButton.css'
import { Link } from 'react-router-dom'


const WhatsappButton = () => {
    return (
        <section>
            <Link to="https://www.whatsapp.com/" target="_blank" rel="noopener noreferrer">
                <img src={process.env.PUBLIC_URL + '/assets/images/logo/whatsapp-logo-whatsapp-icon-whatsapp-transparent-free-png.webp'} className='whatsapp-icon' />
            </Link>
        </section>
    )
}

export default WhatsappButton